/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
  useRouteMatch,
  NavLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import {
  getUserRequest,
  getArticlesRequest,
  onLoad,
  getTargetUserRequest,
  getMoreArticlesRequest,
  getTargetUserSuccess,
  onTargetUserLoad,
  updateSetting,
  updateBackground,
  getUserSuccess,
} from '../../actions';
import Article from '../../pages/Home/Article';
import * as Styles from './UserProfile.style';
import ScrollTopButton from '../ScrollTopButton/index';
import ArticlesLoading from '../Loading';
import { limitOfArticles } from '../../constants';
import { API_URL } from '../../constants/api';
import ImagePopover from '../CreateArticleModal/ImagesPopover';

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { username } = useParams<any>();
  const targetUser = useSelector((state: any) => state.targetUser.targetUser);
  const loadTargetUser = useSelector((state: any) => state.targetUser.loading);
  const [typeOfArticles, setTypeOfArticle] = useState('own');
  const colors = useSelector((state: any) => state.colors);
  const articles = useSelector((state: any) => state.articles);
  const loading = useSelector((state: any) => state.loading);
  const offset = useSelector((state: any) => state.offset);
  const user = useSelector((state: any) => state.user);
  const hasLoadMore = useSelector((state: any) => state.hasLoadMore);
  const backgroundImage1 = targetUser?.image?.split('/backGRound/')[1];
  const [backgroundImage, changeBackgroundImage] = useState('');

  const image =
    targetUser?.image?.split('/backGRound/')[0] ||
    'https://images-na.ssl-images-amazon.com/images/I/61fZ%2BYAYGaL._SX679_.jpg';
  useEffect(() => {
    dispatch(onLoad());
    dispatch(getTargetUserRequest(username));
    dispatch(onTargetUserLoad());
    dispatch(
      getArticlesRequest({
        offset: 0,
        limit: limitOfArticles,
        author: username,
        token: localStorage.getItem('token'),
      })
    );
    setTypeOfArticle('own');
  }, [username]);

  const handleChangeTypeOfArticles = (type: string) => {
    setTypeOfArticle(type);
    dispatch(onLoad());
    dispatch(
      getArticlesRequest({
        offset: 0,
        limit: limitOfArticles,
        author: typeOfArticles === 'favorited' && username,
        favorited: typeOfArticles === 'own' && username,
        token: localStorage.getItem('token'),
      })
    );
  };

  const handleFollow = () => {
    if (user?.userInfo?.token) {
      axios({
        method: targetUser?.following ? 'delete' : 'post',
        url: `${API_URL}/profiles/${targetUser.username}/follow`,
        headers: {
          Authorization: `Token ${user.userInfo.token}`,
        },
      }).then((res: any) => dispatch(getTargetUserSuccess(res.data.profile)));
    } else {
      history.push('/login');
    }
  };

  const handleScroll = () => {
    dispatch(
      getMoreArticlesRequest({
        offset,
        limit: limitOfArticles,
        author: typeOfArticles === 'own' && username,
        favorited: typeOfArticles === 'favorited' && username,
        token: localStorage.getItem('token'),
      })
    );
  };

  const handleChooseAnImage = (image: string) => {
    changeBackgroundImage(image);
  };

  const handleChangeBackground = () => {
    dispatch(
      getUserSuccess({
        ...targetUser,
        image: `${image}/backGRound/${backgroundImage}`,
      })
    );
    dispatch(
      getTargetUserSuccess({
        ...targetUser,
        image: `${image}/backGRound/${backgroundImage}`,
      })
    );
    dispatch(
      updateSetting({
        ...targetUser,
        image: `${image}/backGRound/${backgroundImage}`,
      })
    );
  };

  return (
    <>
      {loadTargetUser ? (
        <h1>Loading</h1>
      ) : (
        <Styles.ContainerAll colors={colors}>
          <Styles.UserBioField
            colors={colors}
            image={backgroundImage || backgroundImage1}
          >
            {JSON.stringify(targetUser) !== '{}' && (
              <Container fluid className="mx-0">
                <Row>
                  <Col
                    className="px-3 d-flex flex-column align-items-center justify-content-center"
                    md={12}
                  >
                    <Styles.UserAvatar
                      src={
                        image ||
                        'https://static.productionready.io/images/smiley-cyrus.jpg'
                      }
                      alt="user avatar"
                    />
                    <Styles.UserBioH1 colors={colors}>
                      {targetUser?.username}
                    </Styles.UserBioH1>
                    <Styles.UserBioText colors={colors}>
                      {targetUser?.bio || 'no bio'}
                    </Styles.UserBioText>
                  </Col>
                  {targetUser?.username === user?.userInfo?.username && (
                    <Styles.GroupBtn>
                      <Styles.ChangeBackground colors={colors}>
                        <ImagePopover
                          handleChooseAnImage={handleChooseAnImage}
                        />
                      </Styles.ChangeBackground>
                      {(backgroundImage || backgroundImage1) !==
                        targetUser?.image?.split('/backGRound/')[1] && (
                        <Styles.ChangeBackground
                          onClick={handleChangeBackground}
                          colors={colors}
                        >
                          ok
                        </Styles.ChangeBackground>
                      )}
                    </Styles.GroupBtn>
                  )}
                </Row>
              </Container>
            )}
          </Styles.UserBioField>
          <Styles.UserArticlesField colors={colors}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <div className="px-0 d-flex align-items-center justify-content-baseline">
                <Styles.Button
                  colors={colors}
                  onClick={() => handleChangeTypeOfArticles('own')}
                  style={{
                    backgroundColor:
                      typeOfArticles === 'own'
                        ? colors.mainColor
                        : colors.cardColor,
                  }}
                >
                  Own
                </Styles.Button>
                <Styles.Button
                  colors={colors}
                  onClick={() => handleChangeTypeOfArticles('favorited')}
                  style={{
                    backgroundColor:
                      typeOfArticles === 'favorited'
                        ? colors.mainColor
                        : colors.cardColor,
                  }}
                >
                  Favorited
                </Styles.Button>
              </div>

              <div>
                {targetUser?.username === user?.userInfo?.username ? (
                  <NavLink
                    to={localStorage.getItem('token') ? `/setting` : '/'}
                    style={{ textDecoration: 'none', paddingTop: '-50px' }}
                  >
                    <Styles.FollowButton colors={colors}>
                      Change your settings
                      <i
                        className="fas fa-arrow-right"
                        style={{ paddingLeft: '5px' }}
                      />
                    </Styles.FollowButton>
                  </NavLink>
                ) : (
                  <Styles.FollowButton colors={colors} onClick={handleFollow}>
                    <i
                      className="fas fa-user-plus"
                      style={{ paddingRight: '5px' }}
                    />
                    {targetUser?.following ? 'Unfollow' : 'Follow'} :{' '}
                    {targetUser?.username}
                  </Styles.FollowButton>
                )}
              </div>
            </div>

            <>
              {loading ? (
                <ArticlesLoading />
              ) : articles.length === 0 ? (
                <Styles.Article colors={colors} style={{ textAlign: 'center' }}>
                  <h2 style={{ padding: '5px' }}>Not Found Any Article</h2>
                </Styles.Article>
              ) : (
                <InfiniteScroll
                  dataLength={articles.length}
                  next={handleScroll}
                  hasMore={hasLoadMore}
                  scrollThreshold={0.8}
                  loader={<ArticlesLoading />}
                >
                  {articles.map((article: any) => (
                    <Article
                      article={article}
                      key={article.slug}
                      colors={colors}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </>
          </Styles.UserArticlesField>
          <ScrollTopButton scrollStepInPx={50} delayTime={2} />
        </Styles.ContainerAll>
      )}
    </>
  );
};

export default UserProfile;
