import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import * as Styles from './Home.style';
import { IColors } from '../../interfaces';
import { deleteArticle, updateArticlesSuccess } from '../../actions';

import formatDate from '../../utils/formatDate';
import { API_URL } from '../../constants/api';
import CreateArticleModal from '../../components/CreateArticleModal';
import checkValidImage from '../../utils/checkValidImage';

interface IArticleProps {
  colors: IColors;
  article: any;
}

const Article: React.FunctionComponent<IArticleProps> = ({
  colors,
  article,
}) => {
  const { url } = useRouteMatch();
  const imageArr = article.body.split('/iMAge/').slice(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: any) => state.user);
  const handleArticleShow = (slug: any) => {
    history.push(`/articles/${slug}`);
  };

  const handleFavoriteBtn = (article: any) => {
    if (user?.userInfo?.token) {
      dispatch(
        updateArticlesSuccess({
          ...article,
          favorited: !article.favorited,
          favoritesCount: article.favorited
            ? article.favoritesCount - 1
            : article.favoritesCount + 1,
        })
      );
      axios({
        method: article.favorited ? 'delete' : 'post',
        url: `${API_URL}/articles/${article.slug}/favorite`,
        headers: {
          Authorization: `Token ${user.userInfo.token}`,
        },
      }).then();
    } else {
      history.push('/login');
    }
  };

  const handleDeleteArticle = (slug: any) => {
    dispatch(deleteArticle({ slug }));
  };

  const handleEditArticle = () => {
    if (localStorage.getItem('token')) {
      setShowModal(!showModal);
    } else {
      history.push('/login');
    }
  };

  const handleClickTag = (e: any, tag: string) => {
    e.stopPropagation();
    history.push(`/home/tags/${tag}`);
  };

  const handleClickUser = () => {
    history.push(`/profile/${article.author.username}`);
  };

  return (
    <>
      <Styles.Article colors={colors}>
        <Styles.ArticleHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Styles.Avatar
              src={
                checkValidImage(article.author.image.split('/backGRound/')[0])
                  ? article.author.image.split('/backGRound/')[0]
                  : 'https://images-na.ssl-images-amazon.com/images/I/61fZ%2BYAYGaL._SX679_.jpg'
              }
              alt={article.author.username}
              onClick={handleClickUser}
              style={{ cursor: 'pointer' }}
            />

            <Styles.InfoContainer>
              <div>
                <div>
                  <h4 style={{ cursor: 'pointer' }} onClick={handleClickUser}>
                    {article.author.username}
                  </h4>
                  <Styles.Time>{formatDate(article.updatedAt)}</Styles.Time>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  alignItems: 'center',
                  marginLeft: '10px',
                }}
              >
                {url.split('/')[1] === 'profile' &&
                  article?.author?.username === user?.userInfo?.username && (
                    <>
                      <Styles.SmallButton
                        colors={colors}
                        onClick={handleEditArticle}
                      >
                        Edit <i className="fas fa-edit fa-md" />
                      </Styles.SmallButton>
                      <Styles.SmallButton
                        colors={colors}
                        onClick={() => handleDeleteArticle(article.slug)}
                      >
                        Delete <i className="fas fa-trash-alt fa-md" />{' '}
                      </Styles.SmallButton>
                    </>
                  )}
              </div>
            </Styles.InfoContainer>

            <CreateArticleModal
              colors={colors}
              showModal={showModal}
              setShowModal={setShowModal}
              articleData={article}
            />
          </div>
          <Styles.ArticleFavorite
            onClick={() => handleFavoriteBtn(article)}
            style={{
              color: `${article.favorited ? '#d1000c' : colors.background}`,
            }}
          >
            <i className="fas fa-heart fa-2x fa-fw" />
          </Styles.ArticleFavorite>
        </Styles.ArticleHeader>
        <Styles.ArticleBody
          onClick={() => handleArticleShow(article.slug)}
          style={{ marginLeft: '4.5rem' }}
        >
          <h3>{article.title}</h3>
          <p>{article.description}</p>
          {article.tagList.length > 0 && (
            <Styles.ArticleTags>
              {_.uniq(
                article.tagList.map((tag: string) =>
                  tag.replaceAll('\u200c', '').replaceAll(' ', '')
                )
              )
                .filter((tag) => tag !== '')
                .map((tag: any, index: any) => (
                  <Styles.ArticleTag
                    key={index}
                    colors={colors}
                    onClick={(e) => handleClickTag(e, tag)}
                  >
                    {tag}
                  </Styles.ArticleTag>
                ))}
            </Styles.ArticleTags>
          )}
          {imageArr.length > 0 && (
            <Styles.ArticleImages images={imageArr.length}>
              {imageArr.slice(0, 3).map((image: any, index: any) => (
                <Styles.ArticleImage
                  key={index}
                  className={`image${index + 1}`}
                  image={image}
                />
              ))}
            </Styles.ArticleImages>
          )}
        </Styles.ArticleBody>
      </Styles.Article>
    </>
  );
};

export default Article;
