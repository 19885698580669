/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import 'toastr/build/toastr.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Styles from './Home.style';
import {
  getMoreArticlesRequest,
  getArticlesRequest,
  onLoad,
} from '../../actions';
import Article from './Article';
import CreateArticleModal from '../../components/CreateArticleModal';
import { limitOfArticles } from '../../constants';
import ArticlesLoading from '../../components/Loading';

const Feed = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { tag } = useParams<any>();
  const type = url.split('/')[2] || 'global';
  const [showModal, setShowModal] = useState(false);
  const colors = useSelector((state: any) => state.colors);
  const articles = useSelector((state: any) => state.articles);
  const offset = useSelector((state: any) => state.offset);
  const loading = useSelector((state: any) => state.loading);
  const user = useSelector((state: any) => state.user);
  const hasLoadMore = useSelector((state: any) => state.hasLoadMore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onLoad());
    dispatch(
      getArticlesRequest({
        offset: 0,
        limit: limitOfArticles,
        tag: type === 'tags' && tag,
        favorited: type === 'favorited' && user?.userInfo?.username,
        token: localStorage.getItem('token'),
      })
    );
  }, [type, tag, user]);
  const handleScroll = () => {
    dispatch(
      getMoreArticlesRequest({
        offset,
        limit: limitOfArticles,
        tag: type === 'tags' && tag,
        favorited: type === 'favorited' && user?.userInfo?.username,
        token: localStorage.getItem('token'),
      })
    );
  };

  const handleShow = () => {
    if (localStorage.getItem('token')) {
      setShowModal(!showModal);
    } else {
      history.push('/login');
    }
  };

  const handleChangeType = (str: string) => {
    history.push(`/home${str === 'favorited' ? '/favorited' : ''}`);
  };
  return (
    <Styles.Feed>
      <CreateArticleModal
        colors={colors}
        showModal={showModal}
        setShowModal={setShowModal}
        articleData={null}
      />
      <Styles.HeaderFeed colors={colors}>
        <Styles.Button
          colors={colors}
          target={type === 'global'}
          onClick={() => handleChangeType('global')}
        >
          Global
        </Styles.Button>
        {user?.userInfo?.username && (
          <Styles.Button
            colors={colors}
            target={type === 'favorited'}
            onClick={() => handleChangeType('favorited')}
          >
            Favorited
          </Styles.Button>
        )}
        <Styles.Article colors={colors} className="firstArticle">
          <Styles.FakeInput colors={colors} onClick={handleShow}>
            Create new article...
          </Styles.FakeInput>
        </Styles.Article>
      </Styles.HeaderFeed>
      <div>
        {loading ? (
          <ArticlesLoading />
        ) : articles.length === 0 ? (
          <Styles.Article colors={colors} style={{ textAlign: 'center' }}>
            <h1>Not Found.</h1>
          </Styles.Article>
        ) : (
          <InfiniteScroll
            dataLength={articles.length}
            next={handleScroll}
            hasMore={hasLoadMore}
            scrollThreshold={0.9}
            loader={<ArticlesLoading />}
          >
            {articles.map((article: any, index: any) => (
              <Article article={article} key={index} colors={colors} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </Styles.Feed>
  );
};

export default Feed;
