import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import toastr from 'toastr';
import * as types from '../actions/actionTypes';
import { getTargetUserSuccess, offTargetUserLoad } from '../actions';

const getTargetUserProfile = (username: any) => {
  return axios.get(
    `https://conduit.productionready.io/api/profiles/${username}`,
    {
      headers: {
        Authorization: localStorage.getItem('token')
          ? `Token ${localStorage.getItem('token')}`
          : '',
      },
    }
  );
};

function* getTargetUserRequest(action: any) {
  try {
    const response = yield call(getTargetUserProfile, action.payload);
    yield put(getTargetUserSuccess(response.data.profile));
    yield put(offTargetUserLoad());
  } catch (error: any) {
    toastr.error('User Not Found');
  }
}

function* targetUserWorker() {
  yield takeLatest(types.GET_TARGET_USER_REQUEST, getTargetUserRequest);
}

export default targetUserWorker;
