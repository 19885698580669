import styled from 'styled-components/macro';
import { IColors } from '../../interfaces';

interface IProps {
  colors: IColors;
}

interface IImage {
  image: string;
  colors: IColors;
}

export const ContainerAll = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.colors.background};
`;

export const UserBioField = styled.div<IImage>`
  width: 60%;
  background: ${(props) =>
    !props.image ? props.colors.cardColor : ` url(${props.image})`};
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 350px;
  border: none;
  margin: 5.3rem 0px 0px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const UserBioH1 = styled.h2<IProps>`
  color: ${(props) => props.colors.mainColor};
  margin: 0px;
  font-size: 3rem;
  text-overflow: ellipsis;
  &:hover {
    text-overflow: clip;
  }
`;

export const UserBioText = styled.p<IProps>`
  color: ${(props) => props.colors.mainColor};
  margin-bottom: 1vh;
  text-overflow: ellipsis;
  &:hover {
    text-overflow: clip;
  }
`;

export const FollowButton = styled.button<IProps>`
  color: #fff;
  padding: 1vh;
  max-width: 185px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${(props) => props.colors.mainColor};
  border: none;
  font-weight: bold;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => props.colors.disableColor};
  }
`;

export const UserAvatar = styled.img`
  height: 20vh !important;
  width: 20vh !important;
  border: none;
  border-radius: 50%;
`;
export const Article = styled.div<IProps>`
  width: 100%;
  border-radius: 5px;
  background-color: ${(props) => props.colors.cardColor};
  &:not(:first-of-type) {
    margin-bottom: 5px;
  }
  &:first-of-type {
    &:hover {
      background-color: ${(props) => props.colors.mainColor};
      cursor: pointer;
    }
  }
`;

export const UserArticlesField = styled.div<IProps>`
  width: 60%;
  background-color: ${(props) => props.colors.background};
  border: none;
  margin: 5px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Button = styled.button<IProps>`
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.colors.cardColor};
  color: ${(props) => props.colors.fontColor};
  padding: 1vh 25px;
  outline: none !important;
  text-decoration: none;
  margin-right: 3px;
  &:focus,
  &:active {
    background-color: ${(props) => props.colors.mainColor};
    color: #fff;
    text-decoration: none;
  }
`;

export const SearchInput = styled.input<IProps>`
  border: none;
  background-color: ${(props) => props.colors.cardColor};
  width: 100%;
  height: 100%;
  color: #fff;
  border-radius: 5px;
  padding-left: 20px;
  &:focus {
    outline: none;
  }
`;

export const ChangeBackground = styled.div<IProps>`
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.colors.background};
  color: ${(props) => props.colors.fontColor};
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const GroupBtn = styled.div`
  align-items: center;
  margin-left: 90%;
`;
