/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Carousel, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import * as Styles from './Home.style';
import formatDate from '../../utils/formatDate';
import {
  deleteCommentsRequest,
  getCommentsRequest,
  onCmtLoad,
  postComment,
} from '../../actions';
import { API_URL } from '../../constants/api';

const ArticleModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { slug } = useParams<any>();
  const colors = useSelector((state: any) => state.colors);
  const comments = useSelector((state: any) => state.comments);
  const user = useSelector((state: any) => state.user);
  const newCommentRef = useRef<any>(null);
  const commentLoading = useSelector((state: any) => state.commentLoading);
  const [loading, setLoading] = useState<any>(true);
  const [targetArticle, setTargetArticle] = useState<any>({});
  const [showBody, setshowBody] = useState(false);
  const bodyRef = useRef<any>(null);
  const imageArr = targetArticle?.body?.split('/iMAge/').slice(1);
  useEffect(() => {
    axios({
      method: 'get',
      url: `https://conduit.productionready.io/api/articles/${slug}`,
      headers: {
        Authorization: localStorage.getItem('token')
          ? `Token ${localStorage.getItem('token')}`
          : '',
      },
    }).then((data) => {
      setTargetArticle(data.data.article);
      setLoading(false);
      dispatch(onCmtLoad());
      dispatch(getCommentsRequest({ slug }));
    });
  }, []);

  const handlePostComment = (e: any) => {
    if (user?.userInfo?.token) {
      if (e.type === 'click' || e.key === 'Enter') {
        dispatch(
          postComment({
            slug,
            comment: newCommentRef.current.value,
            token: user.userInfo.token,
          })
        );

        newCommentRef.current.value = '';
      }
    } else {
      history.push('/login');
    }
  };

  const deleteComment = (id: any) => {
    dispatch(
      deleteCommentsRequest({
        slug,
        id,
        token: user.userInfo.token,
      })
    );
  };

  const handleFavoriteBtn = (article: any) => {
    if (user?.userInfo?.token) {
      setTargetArticle({
        ...article,
        favorited: !article.favorited,
        favoritesCount: article.favorited
          ? article.favoritesCount - 1
          : article.favoritesCount + 1,
      });
      axios({
        method: article.favorited ? 'delete' : 'post',
        url: `${API_URL}/articles/${article.slug}/favorite`,
        headers: {
          Authorization: `Token ${user.userInfo.token}`,
        },
      }).then();
    } else {
      history.push('/login');
    }
  };

  const handleClickTag = (tag: string) => {
    history.push(`/home/tags/${tag}`);
  };

  const handleClickUser = (username: string) => {
    history.push(`/profile/${username}`);
  };

  const handleShowBody = () => {
    setshowBody(!showBody);
    bodyRef.current.scrollTop = 0;
  };

  return (
    <Styles.ArticleProfile>
      {loading ? (
        <Spinner animation="grow" size="sm" />
      ) : (
        <Styles.ArticleContainer
          colors={colors}
          style={{
            gridColumn: imageArr?.length !== 0 ? '2 / span 10' : '3 / span 8',
          }}
        >
          {imageArr?.length !== 0 && (
            <Styles.ImagesContainer>
              <Carousel interval={10000}>
                {imageArr?.map((image: any, index: any) => (
                  <Carousel.Item key={index} style={{}}>
                    <Styles.ShowImage image={image} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Styles.ImagesContainer>
          )}
          {targetArticle?.slug && (
            <Styles.ArticleContent images={imageArr}>
              <Styles.ArticleHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Styles.Avatar
                    src={
                      targetArticle.author.image.split('/backGRound/')[0] ||
                      'https://images-na.ssl-images-amazon.com/images/I/61fZ%2BYAYGaL._SX679_.jpg'
                    }
                    alt={targetArticle?.author?.username}
                    onClick={() =>
                      handleClickUser(targetArticle?.author?.username)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                  <div>
                    <h4
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleClickUser(targetArticle?.author?.username)
                      }
                    >
                      {targetArticle?.author?.username}
                    </h4>
                    <Styles.Time>
                      {formatDate(targetArticle?.updatedAt)}
                    </Styles.Time>
                  </div>
                </div>

                <Styles.HideModal onClick={() => history.goBack()}>
                  <i className="fas fa-times-circle fa-2x fa-fw" />
                </Styles.HideModal>
              </Styles.ArticleHeader>
              <Styles.ArticleBody>
                <h3>{targetArticle.title}</h3>
                <p>{targetArticle.description}</p>
                <p
                  style={
                    showBody ? {} : { maxHeight: '20vh', overflow: 'hidden' }
                  }
                  ref={bodyRef}
                >
                  {targetArticle.body.split('/iMAge/')[0]}
                </p>
                {bodyRef?.current?.clientHeight > '120' && (
                  <span onClick={handleShowBody}>
                    {!showBody ? 'Read more' : 'Hide'}
                  </span>
                )}
                {targetArticle.tagList && (
                  <Styles.ArticleTags>
                    {_.uniq(
                      targetArticle.tagList.map((tag: string) =>
                        tag.replaceAll('\u200c', '').replaceAll(' ', '')
                      )
                    )
                      .filter((tag) => tag !== '')
                      .map((tag: any, index: any) => (
                        <Styles.ArticleTag
                          key={index}
                          colors={colors}
                          onClick={(e) => handleClickTag(tag)}
                        >
                          {tag}
                        </Styles.ArticleTag>
                      ))}
                  </Styles.ArticleTags>
                )}
              </Styles.ArticleBody>
              <hr style={{ margin: '5px 0' }} />
              <Styles.ModalInputCon>
                <Styles.FavoriteBtn
                  onClick={() => handleFavoriteBtn(targetArticle)}
                >
                  <i
                    className="fas fa-heart fa-lg fa-fw"
                    style={{
                      color: `${
                        targetArticle.favorited ? '#d1000c' : colors.background
                      }`,
                      marginTop: '3px',
                    }}
                  />
                  <h4 style={{ margin: '5px' }}>
                    {targetArticle.favoritesCount}
                  </h4>
                </Styles.FavoriteBtn>
                <Styles.Input
                  type="text"
                  colors={colors}
                  placeholder="Write your comment..."
                  ref={newCommentRef}
                  onKeyUp={handlePostComment}
                />
                <Styles.PostBtn colors={colors} onClick={handlePostComment}>
                  Post
                </Styles.PostBtn>
              </Styles.ModalInputCon>
              <Styles.CommentsContainer>
                {commentLoading ? (
                  <div style={{ paddingLeft: '80px' }}>
                    <Spinner animation="grow" size="sm" />
                    <Spinner animation="grow" size="sm" />
                    <Spinner animation="grow" size="sm" />
                  </div>
                ) : (
                  <div>
                    {comments.map((comment: any, index: any) => (
                      <Styles.Comment key={index} colors={colors}>
                        <Styles.AvartarComment
                          src={
                            comment?.author?.image.split('/backGRound/')[0] ||
                            'https://images-na.ssl-images-amazon.com/images/I/61fZ%2BYAYGaL._SX679_.jpg'
                          }
                          alt={comment?.author?.username}
                          onClick={() =>
                            handleClickUser(comment?.author?.username)
                          }
                          style={{ cursor: 'pointer' }}
                        />
                        <div style={{ marginLeft: '5px' }}>
                          <Styles.CommentBody colors={colors}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <h5
                                onClick={() =>
                                  handleClickUser(comment?.author?.username)
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {comment?.author?.username}
                              </h5>
                              {user?.userInfo?.username ===
                                comment?.author?.username && (
                                <i
                                  className="fas fa-times-circle fa-ml fa-fw"
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '-4px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => deleteComment(comment?.id)}
                                />
                              )}
                            </div>

                            <p>{comment?.body}</p>
                          </Styles.CommentBody>
                          <Styles.Time>
                            {formatDate(comment.updatedAt)}
                          </Styles.Time>
                        </div>
                      </Styles.Comment>
                    ))}
                  </div>
                )}
              </Styles.CommentsContainer>
            </Styles.ArticleContent>
          )}
        </Styles.ArticleContainer>
      )}
    </Styles.ArticleProfile>
  );
};

export default ArticleModal;
