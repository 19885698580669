import { Action } from 'redux';
import * as types from '../actions/actionTypes';

interface UserAction extends Action {
  payload: string | any;
}

const targetUser = (
  state = { targetUser: {}, loading: true },
  action: UserAction
) => {
  switch (action.type) {
    case types.GET_TARGET_USER_SUCCESS:
      return {
        ...state,
        targetUser: action.payload,
      };
    case types.ON_TARGET_USER_LOAD:
      return {
        ...state,
        loading: true,
      };
    case types.OFF_TARGET_USER_LOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default targetUser;
