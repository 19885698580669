/* eslint-disable no-nested-ternary */
import React from 'react';
import 'toastr/build/toastr.min.css';

import { useRouteMatch, Switch, Route } from 'react-router-dom';
import * as Styles from './Home.style';
import Navs from './Navs';
import ScrollTopButton from '../../components/ScrollTopButton';
import Feed from './Feed';

const Home = () => {
  const { path } = useRouteMatch();

  return (
    <Styles.Content id="content">
      <Navs />
      <Switch>
        <Route exact path={`${path}/`}>
          <Feed />
        </Route>
        <Route exact path={`${path}/favorited`}>
          <Feed />
        </Route>
        <Route exact path={`${path}/tags/:tag`}>
          <Feed />
        </Route>
      </Switch>
      <ScrollTopButton scrollStepInPx={50} delayTime={2} />
    </Styles.Content>
  );
};

export default Home;
