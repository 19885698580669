import React, { memo, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import * as Styles from './Home.style';
import { API_URL } from '../../constants/api';

const Navs = () => {
  const user = useSelector((state: any) => state.user);
  const colors = useSelector((state: any) => state.colors);
  const [tags, setTags] = useState([]);
  const history = useHistory();
  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/tags`,
    }).then((data) => setTags(data.data.tags));
  }, []);

  const handleRedirectTag = (tag: string) => {
    history.push(`/home/tags/${tag}`);
  };
  return (
    <Styles.Nav colors={colors}>
      <Styles.NavItem colors={colors} onClick={() => history.push('/home')}>
        <i className="fas fa-h-square fa-lg fa-fw" />
        <h4>Home</h4>
      </Styles.NavItem>
      <hr style={{ margin: '5px 0' }} />
      <NavLink
        to={
          localStorage.getItem('token')
            ? `/profile/${user?.userInfo?.username}`
            : '/login'
        }
        style={{ textDecoration: 'none', paddingTop: '-50px' }}
      >
        <Styles.NavItem colors={colors}>
          <i className="fas fa-user fa-lg fa-fw" />
          <h4>Profile</h4>
        </Styles.NavItem>
      </NavLink>

      <hr style={{ margin: '5px 0' }} />

      <NavLink
        to={localStorage.getItem('token') ? '/setting' : '/login'}
        style={{ textDecoration: 'none', paddingTop: '-50px' }}
      >
        <Styles.NavItem colors={colors}>
          <i className="fas fa-cog fa-lg fa-fw" />
          <h4>Setting</h4>
        </Styles.NavItem>
      </NavLink>

      <hr style={{ margin: '5px 0' }} />

      <NavLink
        to={localStorage.getItem('token') ? '/chat' : '/login'}
        style={{ textDecoration: 'none', paddingTop: '-50px' }}
      >
        <Styles.NavItem colors={colors}>
          <i className="fas fa-users fa-lg fa-fw" />
          <h4>&nbsp;Chatroom</h4>
        </Styles.NavItem>
      </NavLink>

      <hr style={{ margin: '5px 0' }} />

      <Styles.NavItem colors={colors}>
        <i className="fas fa-tags fa-lg fa-fw" />
        <h4>&nbsp;Tags</h4>
      </Styles.NavItem>
      <div
        style={{
          overflow: 'hidden',
          overflowY: 'auto',
          maxHeight: '40vh',
          // width: '80%',
        }}
      >
        {_.uniq(tags.map((tag: string) => tag.replaceAll('\u200c', '')))
          .slice(1)
          .map((tag: any, index: any) => (
            <Styles.Tag
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => handleRedirectTag(tag)}
            >
              #{tag}
            </Styles.Tag>
          ))}
      </div>
    </Styles.Nav>
  );
};

export default Navs;
