export const CHANGE_MODE = 'CHANGE_MODE';

export const CHANGE_OFFSET = 'CHANGE_OFFSET';

export const ON_LOAD = 'ON_LOAD';
export const OFF_LOAD = 'OFF_LOAD';

export const ON_CMT_LOAD = 'ON_CMT_LOAD';
export const OFF_CMT_LOAD = 'OFF_CMT_LOAD';

export const HAS_LOAD_MORE = 'HAS_LOAD_MORE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';

export const GET_ARTICLES_REQUEST = 'GET_ARTICLES_REQUEST';
export const GET_MORE_ARTICLES_REQUEST = 'GET_MORE_ARTICLES_REQUEST';
export const ADD_ARTICLES = 'ADD_ARTICLES';
export const CHANGE_ARTICLES = 'CHANGE_ARTICLES';
export const UPDATE_ARTICLES_REQUEST = 'UPDATE_ARTICLES_REQUEST';
export const UPDATE_ARTICLES_SUCCESS = 'UPDATE_ARTICLES_SUCCESS';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const POST_COMMENT = 'POST_COMMENT';
export const ADD_COMMENT = 'ADD_COMMENT';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';

export const SIGN_OUT = 'SIGN_OUT';

export const POST_ARTICLE_REQUEST = 'POST_ARTICLE_REQUEST';
export const POST_ARTICLE_SUCCESS = 'POST_ARTICLE_SUCCESS';
export const POST_ARTICLE_FAIL = 'POST_ARTICLE_FAIL';

export const SETTING_UPDATE = 'SETTING_UPDATE';
export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS';
export const SETTING_UPDATE_FAIL = 'SETTING_UPDATE_FAIL';

export const GET_TARGET_USER_REQUEST = 'GET_TARGET_USER_REQUEST';
export const GET_TARGET_USER_SUCCESS = 'GET_TARGET_USER_SUCCESS';

export const ON_TARGET_USER_LOAD = 'ON_TARGET_USER_LOAD';
export const OFF_TARGET_USER_LOAD = 'OFF_TARGET_USER_LOAD';

export const UPDATE_BACKGROUND = 'UPDATE_BACKGROUND';
